import React from "react";
import Button, { ButtonSize, ButtonVariant } from "../Button";
import Icon, { IconType } from "../Icon";
import { Trans, useTranslation } from 'gatsby-plugin-react-i18next';
import { nav } from "../../utils/navigation";
import { URL } from "../../constants/destinations";

export enum PricingCardVariant {
  Starter = "starter",
  Growth = "growth",
  Pro = "pro",
  Enterprise = "enterprise"
}

interface PricingCardProps {
  variant: PricingCardVariant;
  onClick?: () => void;
  buttonId?: string;
}

const starterFeatures = {
  information: [
    "Onboarding call",
    "Access to Perpetua Support Team",
  ]
};
const growthFeatures = {
  includes: ["Starter"],
  information: ["Dedicated eCommerce Associate"]
};

const proFeatures = {
  includes: ["Growth"],
  information: [
    "Dedicated Data Strategist",
    "3 & 6-week checkpoint call",
    "Customized launch strategy & onboarding plan",
    "Quarterly business review"
  ]
};

const enterpriseFeatures = {
  includes: ["Growth", "Pro"],
  information: [
    "White-glove account set-up",
    "Early access to Perpetua Betas (including DSP self serve)",
    "Amazon Marketing Cloud integration",
    "Custom dashboard production"
  ]
};

const Divider: React.FC<{}> = () => (
  <div className="flex flex-row justify-center">
    <div className="w-10/12 border-t border-gray-5-light"></div>
  </div>
);

const PricingCard: React.FC<PricingCardProps> = props => {
  const { variant, onClick, buttonId } = props;

  const {t} = useTranslation();
  
  const parseFeatures = (features: { [key: string]: Array<string> }) => {
    return (
      <div className="space-y-6 w-10/12 mb-7 mx-auto">
        {Object.entries(features).map(entry => {
          const [category, features] = entry;
          if (category === "includes") {
            let item = features.reduce(
              (prev, cur) => (`${prev}${prev ? ` + ${cur}` : null}`)
            );
            return (
              <div className="flex flex-row">
                <Icon iconType={IconType.plus} />
                <div className="ml-4 flex flex-col justify-center text-lg text-gray-3-dark font-medium">
                  <span>
                      {t(`All features in ${item}`)}
                  </span>
                </div>
              </div>
            );
          }
          return (
            <div className="space-y-6">
              {features.map(feature => (
                <div className="flex flex-row">
                  <div className="flex flex-col justify-center">
                    <Icon iconType={IconType.checkmark} />
                  </div>
                  <div className="ml-4 flex flex-col justify-center text-lg font-medium text-gray-3-dark">
                    {t(feature)}
                  </div>
                </div>
              ))}
            </div>
          );
        })}
      </div>
    );
  };

  const price = React.useMemo(() => {
    switch (variant) {
      case PricingCardVariant.Starter:
        return (
          <div className="flex flex-row justify-center">
            <div className="text-lg font-semibold font-gray-3-dark text-center mt-8 mb-2 mr-2">
              <span className="text-4xl">{t("$250/mth")}</span>
            </div>
          </div>
        );
      case PricingCardVariant.Growth:
        return (
          <div className="flex flex-row justify-center">
            <div className="text-lg font-semibold font-gray-3-dark text-center mt-8 mb-2 mr-2">
              <span className="text-4xl">{t("$500/mth")}</span>
            </div>
          </div>
        );
      case PricingCardVariant.Pro:
        return (
          <div className="text-lg font-semibold font-gray-3-dark text-center mt-8 mb-2">
            <span className="text-4xl">{t("$500/mth + % of ad spend")}</span>
          </div>
        );
      case PricingCardVariant.Enterprise:
        return (
          <div className="text-4xl font-semibold font-gray-3-dark text-center mt-8 mb-2">
            {t("Custom Pricing")}
          </div>
        );
    }
  }, [variant]);

  return (
    <div className="relative w-full lg:w-1/3 bg-white rounded-lg shadow">
      <div className="my-8 text-2xl font-semibold text-center text-gray-3-dark">
        {variant === PricingCardVariant.Starter ? `${t("Starter")} 🌱`: null}
        {variant === PricingCardVariant.Growth ? `${t("Growth")} 🌿 ` : null}
        {variant === PricingCardVariant.Pro ? `${t("Pro")} 🌳` : null}
        {variant === PricingCardVariant.Enterprise ? `${t("Enterprise")} 🌎` : null}
      </div>
      <Divider />
      {price}
      <div className="text-base text-gray-1 font-medium text-center">
        {variant === PricingCardVariant.Starter
          ? t("Up to $5,000 in monthly ad spend")
          : variant === PricingCardVariant.Growth
          ? t("Up to $10,000 in monthly ad spend")
          : variant === PricingCardVariant.Pro
          ? t("Up to $200,000 in monthly ad spend")
          : t("Over $200,000 in monthly ad spend")}{" "}
      </div>
      <div className="border-indigo-1 border-t-2 w-4 mx-auto my-[16px]" />
      <div className="text-base text-gray-1 font-medium text-center">
        {variant === PricingCardVariant.Starter
          ? t("Single marketplace access")
          : variant === PricingCardVariant.Growth
          ? t("Access to 2 marketplaces")
          : variant === PricingCardVariant.Pro
          ? t("Unlimited marketplace access") + "*"
          : t("Unlimited marketplace access")}
      </div>
      <div className="flex flex-row justify-center my-8">
        {variant === PricingCardVariant.Enterprise ? (
          <Button
            id={buttonId}
            text={t("Contact Us")}
            variant={ButtonVariant.primary}
            size={ButtonSize.large}
            className="w-10/12 self-center"
            onClick={()=> nav(URL.SignUp)}
          />
        ) : (
          <Button
            id={buttonId}
            text={t("Get Started")}
            variant={ButtonVariant.primary}
            size={ButtonSize.large}
            className="w-10/12 self-center"
            onClick={()=> nav(URL.SignUp)}
          />
        )}
      </div>
      {variant === PricingCardVariant.Starter && parseFeatures(starterFeatures)}
      {variant === PricingCardVariant.Growth && parseFeatures(growthFeatures)}
      {variant === PricingCardVariant.Pro && parseFeatures(proFeatures)}
      {variant === PricingCardVariant.Enterprise &&
        parseFeatures(enterpriseFeatures)}
    </div>
  );
};

export default PricingCard;
