import React from "react";
import { graphql } from "gatsby";
import BottomCTA from "../components/BottomCta";
import { NavigationBarVariant } from "../components/NavigationBar/models";
import NavigationBarSpacer from "../components/NavigationBarSpacer";
import PricingCard, { PricingCardVariant } from "../components/PricingCard";
import SEO from "../components/Seo";
import Layout from "../containers/Layout";
import Section from "../containers/Section";
import { nav } from "../utils/navigation";
import { CTA } from "../constants/text";
import { useTranslation } from 'gatsby-plugin-react-i18next';

const PricingPage: React.FC<{}> = () => {
  const {t} = useTranslation();

  return (
    <Layout
      navigationBarVariant={NavigationBarVariant.default}
      primaryButtonId="pricing-nav-getstarted"
    >
      <SEO
        title="Perpetua Pricing & Plans"
        description="Flexible pricing that scales as you grow. Request a demo or chat with our sales team to learn more about our pricing and plans."
      />
      <div className="bg-gray-5 pb-24">
        <div className="h-240 pt-24">
          <NavigationBarSpacer />
          <Section className="-mt-8 sm:mt-0">
            <div className="text-4xl sm:text-6xl font-bold text-center text-gray-3-dark justify-self-center">
             {t("Simple Pricing")}
            </div>
          </Section>
        </div>
        <Section className="mt-32">
          <div className="-mt-200 sm:-mt-170 sm:pb-8 pb-20 flex lg:flex-row flex-col lg:space-x-6 space-y-6 lg:space-y-0">
            <PricingCard
              buttonId="pricing-starter-getstarted"
              variant={PricingCardVariant.Starter}
            />
            <PricingCard
              buttonId="pricing-growth-getstarted"
              variant={PricingCardVariant.Growth}
            />
            <PricingCard
              buttonId="pricing-pro-getstarted"
              variant={PricingCardVariant.Pro}
            />
            <PricingCard
              buttonId="pricing-enterprise-contact"
              variant={PricingCardVariant.Enterprise}
            />
          </div>
          <div className="flex justify-center h-20 bg-gray-6 w-full mb-[100px] text-center">
            <p className="text-gray-2 text-base my-auto">
              *{t("Minimum fee of $250 per activated marketplace")}
            </p>
          </div>
        </Section>

        <BottomCTA
          primaryButtonId="pricing-agency-learnmore"
          secondaryButtonId="pricing-agency-contact"
          title={t("Managing multiple accounts?")}
          subtitle={t("Ask us about our Agency plans")}
          primaryButtonText={t(CTA.Learn)}
          secondaryButtonText={t(CTA.Contact)}
          primaryButtonOnClick={() => nav("/agencies")}
        />
      </div>
    </Layout>
  );
};

export default PricingPage;


export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;